import React, { Suspense, lazy } from "react";
import "./App.css";
import PrivateRoute from "./privateAuth/privateAuth";
import styledRoot from "./styledRoot";
import withStyles from "@material-ui/core/styles/withStyles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./i18n";
import { Switch, Route, BrowserRouter } from "react-router-dom";
const ResetPassword = lazy(() => import("./container/login/resetPassword"));
const Login = lazy(() => import("./container/login"));
const ForgetPassword = lazy(() => import("./container/login/forgetPassword"));
const Container = lazy(() => import("./container/home"));

const Loader = ({ classes }) => (
  <Backdrop className={classes.backdrop} open={true}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

function App({ classes }) {
  return (
    <Suspense fallback={<Loader classes={classes} />}>
      <Switch>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/forget" component={ForgetPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <PrivateRoute>
          <Route path="/" component={Container} />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.gray,
    background: theme.palette.primary.dashboardBackground,
  },
});

export default styledRoot(withStyles(styles)(App));
