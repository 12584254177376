import ReduxThunk from 'redux-thunk';
import AsyncStorage from "@react-native-community/async-storage";
import { persistReducer } from 'redux-persist'
import loginReducers from './reducers/loginReducers';
import { createStore, compose, applyMiddleware } from 'redux';


const persistConfig = {
    key: 'root',
    storage: AsyncStorage
}

const persistedReducer = persistReducer(persistConfig, loginReducers);

const store = createStore(persistedReducer, compose(applyMiddleware(ReduxThunk)));

export default store;