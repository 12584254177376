import { LOGIN_REQUEST, LOGIN_SUCCESS , LOGIN_FAILED} from "../../constant"


const intialState = {
    loading: false,
    error: false,
    info: {}
}

const loginReducers = (state = intialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST: {
            return {
                ...state,
                error: false,
                loading: true
            }
        }

        case LOGIN_SUCCESS: {
            return {
                ...state,
                info: action.info,
                loading: false,
            }
        }

        case LOGIN_FAILED: {
            return {
                ...state,
                info: action.err,
                error: true,
                loading: false,
            }
        }
    }
}

export default loginReducers;