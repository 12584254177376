
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {checkToken} from './auth'



const PrivateRoute = ({children, ...rest}) => {
    return (
        <Route
            {...rest}
            render={({location}) => {
                return checkToken() ? (children) :
                    (<Redirect
                        to={{
                            pathname: "/login",
                            // state: {from: location}
                        }}
                    />)
            }}
        />
    )
}

export default PrivateRoute;